import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import NoPage from './components/NoPage/NoPage';
import Layout from './components/Navbar/Layout';
import { CompanyForm } from './components/Company/CompanyForm';
import { StatusForm } from './components/Status/StatusForm';
import Auth from './components/Auth/Auth';
import ProtectedRoute from './components/ProtectedRoute';
import RoleIndex from './components/Roles/RoleIndex';
import { SimpleTaskMain } from './components/SimpleTask/SimpleTaskMain';
import { ProjectForm } from './components/Project/ProjectForm';
import { ProjectGroupForm } from './components/ProjectGroupListitems/ProjectGroupForm';
import { PriorityForm } from './components/Priority/PriorityForm';
import { UserForm } from './components/Users/UserForm';
import { MachineForm } from './components/Machines/MachineForm';
import TypeForm from './components/Type/TypeForm';
import MyTodoTable from './components/MyTodo/MyTodoTable';
import { LoaderProvider } from './provider/LoaderProvider';
import LoaderUI from './components/Interface/LoaderUI';
import KanbanView from './components/Kanban/KanbanView';
import TaskevaluationForm from './components/TaskEvaluation/TaskevaluationForm';
import TaskGanttForm from './components/TaskGantt/TaskGanttForm';
import { SimpleTaskPhoneForm } from './components/SimpleTaskPhone/SimpleTaskPhoneForm';
import SapResourcePlanner from './components/SapResourcePlanner/SapResourcePlanner';
import ActiveTimePage from './components/ActiveTime/ActiveTimePage';

import { MenuProvider } from './context/MenuContext';
import { UsersProvider } from './context/UsersContext';
import { PageProvider } from './context/PageContext';
import { ParamProvider } from './context/ParamContext';
import { ProductionForm } from './components/Production/ProductionForm';
import { OperationForm } from './components/Operation/OperationForm';
import { QuantityUnitForm } from './components/QuantityUnit/QuantityUnitForm';
import { TaskReportForm } from './components/TaskReport/TaskReportForm';
import { ProductionReportForm } from './components/ProductionReport/ProductionReportForm';
import { CompanyLocationForm } from './components/CompanyLocation/CompanyLocationForm';

const App = () => {
  return (
    <LoaderProvider>
      <PageProvider>
        <MenuProvider>
          <ParamProvider>
            <UsersProvider>
              <LoaderUI />
              <SnackbarProvider hideIconVariant>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Auth />} />
                    <Route path="/app" element={<ProtectedRoute element={<Layout />} />}>
                      <Route index element={<MyTodoTable />} />
                      <Route path="project/:id" element={<ProjectForm />} />
                      <Route path="company/:id" element={<CompanyForm />} />
                      <Route path="status/:id" element={<StatusForm />} />
                      <Route path="projectGroup/:id" element={<ProjectGroupForm />} />
                      <Route path="Categories/:id" element={<PriorityForm />} />
                      <Route path="type/:id" element={<TypeForm />} />
                      <Route path="machine/:id" element={<MachineForm />} />
                      <Route path="operation/:id" element={<OperationForm />} />
                      <Route path="quantityunit/:id" element={<QuantityUnitForm />} />
                      <Route path="companylocation/:id" element={<CompanyLocationForm />} />

                      <Route path="simpleTask/:id" element={<SimpleTaskMain />} />
                      <Route path="mytodo/:id" element={<MyTodoTable />} />
                      <Route path="simpleTaskPhone/:id" element={<SimpleTaskPhoneForm />} />
                      <Route path="production/:id" element={<ProductionForm />} />
                      <Route path="taskReport/:id" element={<TaskReportForm />} />
                      <Route path="productionreport/:id" element={<ProductionReportForm />} />

                      <Route path="user/:id" element={<UserForm />} />
                      <Route path="roles/:id" element={<RoleIndex />} />

                      <Route path="taskkanban/:id" element={<KanbanView />} />
                      <Route path="taskevaluation/:id" element={<TaskevaluationForm />} />
                      <Route path="taskgantt/:id" element={<TaskGanttForm />} />

                      <Route path="activeTime/:id" element={<ActiveTimePage />} />
                      <Route path="sapresourceplanner/:id" element={<SapResourcePlanner />} />

                      <Route path="*" element={<NoPage />} />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </SnackbarProvider>
            </UsersProvider>
          </ParamProvider>
        </MenuProvider>
      </PageProvider>
    </LoaderProvider>
  );
};

export default App;
