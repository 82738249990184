import { useEffect, useState } from 'react';
import kanbanTaskService from '../../service/kanbanTask.service';
import { useLoader } from '../../provider/LoaderProvider';
import simpletaskService from '../../service/simpletask.service';

import { ColorPicker } from '../../config/ColorPicker';
import useParam from '../../context/ParamContext';
import useUsers from '../../context/UsersContext';
import { generateFilter } from '../../utils/helper';
import TodoDialog from '../MyTodo/TodoDialog';
import { AutoCompleteSelectUI, ButtonUI, DebouncedInputUI, FormLabelUI } from '../Interface';
import KanbanCard from './KanbanCard';

const KanbanView = () => {
  const { getUser, user } = useUsers();
  const { getParam, params } = useParam();
  const { showLoader, hideLoader } = useLoader();
  const [kanbanData, setKanbanData] = useState([]);
  const [task, setTask] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(null);
  const [filterFormState, setFilterFormState] = useState({ simpleTaskName: '', resposiblePersonId: '' });
  const [defaultFilterFields, setDefaultFilterFields] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
  });

  useEffect(() => {
    getUser();
    getParam();
  }, []);

  const kanbanViewAccess =
    params.KANBAN_VIEW_ACCESS && params.KANBAN_VIEW_ACCESS.split(',').map((access) => parseInt(access));

  const hasAccess = kanbanViewAccess && kanbanViewAccess.includes(user.userId) ? 'Y' : 'N';

  const conditions = {
    like: ['simpleTaskName'],
  };

  useEffect(() => {
    if (user.userId) {
      setFilterFormState((prevState) => ({
        ...prevState,
        resposiblePersonId: user.userId,
      }));
    }
  }, [user]);

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
    setDefaultFilterFields(defaultFilter);
  }, [filterFormState]);

  useEffect(() => {
    if (defaultFilterFields.length > 0 && query.filter.length > 0) {
      getTasks();
    }
  }, [query]);

  const getTasks = () => {
    showLoader();
    kanbanTaskService
      .getTasks(query, hasAccess)
      .then((data) => {
        setKanbanData(data);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getTaskById = (simpleTaskId) => {
    showLoader();
    simpletaskService
      .getTaskById(simpleTaskId)
      .then((data) => {
        setTask(data);
        setDialogOpen(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleDropAndStatusUpdate = (statusId, task) => {
    setLoading(task.simpleTaskId);
    const updatedTask = { ...task, statusId };

    setKanbanData((prevKanbanData) => {
      const updatedKanbanData = prevKanbanData.map((kanbanTask) => {
        if (kanbanTask.itemId === statusId) {
          return {
            ...kanbanTask,
            simpleTask: [updatedTask, ...kanbanTask.simpleTask],
          };
        }
        if (kanbanTask.itemId === task.statusId) {
          return {
            ...kanbanTask,
            simpleTask: kanbanTask.simpleTask.filter((t) => t.simpleTaskId !== task.simpleTaskId),
          };
        }
        return kanbanTask;
      });
      return updatedKanbanData;
    });

    simpletaskService.updateStatus(updatedTask).finally(() => {
      setLoading(null);
    });
  };

  const onDragStart = (event, task) => {
    event.dataTransfer.setData('task', JSON.stringify(task));
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event, statusId) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    const task = JSON.parse(event.dataTransfer.getData('task'));

    if (task.statusId !== statusId) {
      handleDropAndStatusUpdate(statusId, task);
    }
  };

  return (
    <div className="bg-gray-100 w-full px-3 h-screen md:fixed z-40">
      <div className="absolute -top-14 right-20  mt-1">
        <ButtonUI text="Frissítés" className="bg-success" onClick={getTasks}></ButtonUI>
      </div>
      <div className="absolute -top-16  mt-1" style={{ left: '300px' }}>
        <DebouncedInputUI
          label="Feladat neve"
          debounceMs={800}
          setQuickFilterSearchValue={(newValue) => {
            setFilterFormState((prevState) => ({
              ...prevState,
              simpleTaskName: newValue,
            }));
          }}
          quickFilterSearchValue={filterFormState.simpleTaskName}
        />
      </div>
      <div className="absolute -top-16 mt-1" style={{ left: '550px', width: '250px' }}>
        <AutoCompleteSelectUI
          variant="standard"
          onChange={(_e, newVal, reason) => {
            if (reason === 'clear') {
              setFilterFormState((prev) => ({
                ...prev,
                resposiblePersonId: '',
              }));
            } else {
              setFilterFormState((prev) => ({
                ...prev,
                resposiblePersonId: newVal.value,
              }));
            }
          }}
          selectedValue={filterFormState.resposiblePersonId}
          selectedLabelValue={user?.userName}
          label={<FormLabelUI text="Felelős" />}
          table={'WebUser'}
          listType={{ id: 'userId', name: 'userName' }}
          conditions={[`WebUser.archive = 'N'`]}
        />
      </div>
      <div className="flex flex-col gap-5 justify-center mt-4 md:flex-grow md:flex-row">
        {kanbanData.map((taskkanban) => {
          const statusColor = ColorPicker.find((col) => col.colorName === taskkanban.color);
          return (
            <div
              className="basis-1/4 text-labelColor relative"
              key={taskkanban.itemId}
              onDragOver={(event) => onDragOver(event)}
              onDrop={(event) => onDrop(event, taskkanban.itemId)}
            >
              <div
                className="bg-white py-4 pl-2 shadow-xl text-center text-xl font-bold"
                style={{ borderBottom: `3px solid ${statusColor?.colorCode}` }}
              >
                {taskkanban.itemName}
              </div>
              <div
                className="bg-kanbanBg shadow-xl w-full h-[350px] p-3 overflow-y-auto pb-10 md:h-screen"
                style={{ maxHeight: 'calc(100vh - 160px)' }}
              >
                {taskkanban.simpleTask.map((task) => {
                  return (
                    <KanbanCard
                      key={task.simpleTaskId}
                      task={task}
                      onDragStart={onDragStart}
                      getTaskById={getTaskById}
                      statusColor={statusColor}
                      loading={loading === task.simpleTaskId ? loading : null}
                    />
                  );
                })}
              </div>
              <div className="absolute bottom-0 z-50 bg-kanbanBg w-full py-1 flex justify-center">
                <p className="font-bold">Feladatok száma: {taskkanban.simpleTask.length}</p>
              </div>
            </div>
          );
        })}
      </div>
      <TodoDialog
        open={dialogOpen}
        task={task}
        handleClose={() => {
          setDialogOpen(false);
          setTask({});
        }}
      />
    </div>
  );
};

export default KanbanView;
