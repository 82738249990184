import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { generateFilter, dataGridColumns } from '../../utils/helper';
import simpletaskService from '../../service/simpletask.service';
import { AutoCompleteSelectUI, DatePickerUI, DataGridUI, DebouncedInputUI } from '../Interface';
import useSaveView from '../../hooks/useSaveView';
import useView from '../../hooks/useView';

const SimpleTaskReports = ({ simpleTaskState }) => {
  const { setRefetchTable, setSelectedValues, viewField, operatorsDisabled, refetchTable, submenu, params } =
    simpleTaskState;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const saveView = useSaveView();
  const savedFilters = useView('simpleTaskReportsFilter');
  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });
  const [filterFormState, setFilterFormState] = useState({
    startDate: null,
    endDate: null,
    projectId: '',
    userId: '',
    resposiblePersonId: '',
    simpleTaskTypeId: '',
    machineId: '',
    simpleTaskName: '',
    statusId: '',
    companyLocationId: '',
  });

  useEffect(() => {
    if (savedFilters) {
      setFilterFormState((prevState) => ({
        ...prevState,
        startDate: savedFilters.startDate ? dayjs(savedFilters.startDate) : null,
        endDate: savedFilters.endDate ? dayjs(savedFilters.endDate) : null,
        userId: savedFilters.userId?.value || '',
        resposiblePersonId: savedFilters.resposiblePersonId?.value || '',
        projectId: savedFilters.projectId?.value || '',
        simpleTaskTypeId: savedFilters.simpleTaskTypeId?.value || '',
        machineId: savedFilters.machineId?.value || '',
        simpleTaskName: savedFilters.simpleTaskName || '',
        statusId: savedFilters.statusId?.value || '',
        companyLocationId: savedFilters.companyLocationId?.value || '',
      }));
    }
  }, [savedFilters]);

  const conditions = {
    like: ['simpleTaskName'],
    gte: ['startDate'],
    lte: ['endDate'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  useEffect(() => {
    // @TODO táblázat se mutassa a selected sort
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    simpletaskService.getTasks(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'prefixedId',
      headerName: 'Azonostó',
      flex: 1,
      sortable: false,
    },
    {
      field: 'startDate',
      headerName: 'Kezdő időpont',
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.startDate).format('YYYY.MM.DD. HH:mm');
      },
    },
    {
      field: 'endDate',
      headerName: 'Befejező időpont',
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.endDate).format('YYYY.MM.DD. HH:mm');
      },
    },
    {
      field: 'simpleTaskName',
      headerName: 'Feladat',
      flex: 1.5,
    },
    ...(params.SIMPLETASK_PROJECT_FIELD === 'Y'
      ? [
          {
            field: 'projectName',
            headerName: 'Projekt',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
              return params.row.project?.projectName;
            },
          },
        ]
      : []),
    {
      field: 'userName',
      headerName: 'Tulajdonos',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.user?.userName;
      },
    },
    {
      field: 'responsiblePersonName',
      headerName: 'Felelős',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.responsiblePerson?.userName;
      },
    },
    {
      field: 'projectPrepare',
      headerName: '%',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        const preparedness = params.row.preparedness ?? '';
        return `${preparedness}%`;
      },
    },
    ...(params.SIMPLETASK_RESOURCE_FIELD === 'Y'
      ? [
          {
            field: 'machineName',
            headerName: 'Erőforrás',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
              return params.row.machine?.machineName;
            },
          },
        ]
      : []),
    {
      field: 'priorityName',
      headerName: 'Prioritás',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.priority?.itemName;
      },
    },
    {
      field: 'statusName',
      headerName: 'Státusz',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.status?.itemName;
      },
    },
    ...(params.SIMPLETASK_COMPANY_LOCATION_FIELD === 'Y'
      ? [
          {
            field: 'companyLocationId',
            headerName: 'Telephely',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
              return params.row.companyLocation?.name;
            },
          },
        ]
      : []),
    {
      field: 'simpleTaskTypeName',
      headerName: 'Feladat típusa',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.taskType?.itemName;
      },
    },
  ]);

  return (
    <>
      <div className="col-span-4 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="col h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto xxl:overflow-x-hidden">
              <div className="sticky top-0 bg-white">
                <div className="pt-2 ">
                  <div className="grid grid-cols-5 gap-x-4 mx-4">
                    <div className="col-span-2 md:col-span-1">
                      <DatePickerUI
                        label={<span className="text-labelColor">Kezdő dátum</span>}
                        value={filterFormState.startDate}
                        onChange={(newDate) => {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            startDate: newDate ? newDate.startOf('day') : null,
                          }));
                          saveView('simpleTaskReportsFilter', { startDate: newDate });
                        }}
                      />
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <DatePickerUI
                        label={<span className="text-labelColor">Befejező dátum</span>}
                        value={filterFormState.endDate}
                        onChange={(newDate) => {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            endDate: newDate ? newDate.endOf('day') : null,
                          }));
                          saveView('simpleTaskReportsFilter', { endDate: newDate });
                        }}
                      />
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <DebouncedInputUI
                        label="Feladat neve"
                        debounceMs={800}
                        InputLabelProps={{
                          style: { color: '#607d8b' },
                        }}
                        quickFilterSearchValue={savedFilters?.simpleTaskName}
                        setQuickFilterSearchValue={(newValue) => {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            simpleTaskName: newValue,
                          }));
                          saveView('simpleTaskReportsFilter', { simpleTaskName: newValue });
                        }}
                      />
                    </div>

                    <div className="col-span-2 md:col-span-1">
                      <AutoCompleteSelectUI
                        id="worker"
                        label={<span className="text-labelColor">Tulajdonos</span>}
                        selectedValue={filterFormState.userId}
                        value={savedFilters?.userId?.label || ''}
                        table="WebUser"
                        listType={{ id: 'userId', name: 'userName' }}
                        onChange={(_e, newVal, reason) => {
                          if (reason === 'clear') {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              userId: '',
                            }));
                          } else {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              userId: newVal.value,
                            }));
                          }
                          saveView('simpleTaskReportsFilter', { userId: newVal });
                        }}
                      />
                    </div>

                    <div className="col-span-2 md:col-span-1">
                      <AutoCompleteSelectUI
                        id="resposiblePerson"
                        label={<span className="text-labelColor">Felelős</span>}
                        table="WebUser"
                        selectedValue={filterFormState.resposiblePersonId}
                        value={savedFilters?.resposiblePersonId?.label || ''}
                        listType={{ id: 'userId', name: 'userName' }}
                        onChange={(_e, newVal, reason) => {
                          if (reason === 'clear') {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              resposiblePersonId: '',
                            }));
                          } else {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              resposiblePersonId: newVal.value,
                            }));
                          }
                          saveView('simpleTaskReportsFilter', { resposiblePersonId: newVal });
                        }}
                      />
                    </div>

                    {params.SIMPLETASK_PROJECT_FIELD === 'Y' && (
                      <div className="col-span-2 md:col-span-1">
                        <AutoCompleteSelectUI
                          id="Submenus"
                          label={<span className="text-labelColor">Projekt</span>}
                          selectedValue={filterFormState.projectId}
                          value={savedFilters?.projectId?.label || ''}
                          table="project"
                          listType={{ id: 'projectId', name: 'projectName' }}
                          onChange={(_e, newVal, reason) => {
                            if (reason === 'clear') {
                              setFilterFormState((prevState) => ({
                                ...prevState,
                                projectId: '',
                              }));
                            } else {
                              setFilterFormState((prevState) => ({
                                ...prevState,
                                projectId: newVal.value,
                              }));
                            }
                            saveView('simpleTaskReportsFilter', { projectId: newVal });
                          }}
                        />
                      </div>
                    )}

                    <div className="col-span-2 md:col-span-1">
                      <AutoCompleteSelectUI
                        id="shift"
                        label={<span className="text-labelColor">Feladat típusa</span>}
                        selectedValue={filterFormState.simpleTaskTypeId}
                        value={savedFilters?.simpleTaskTypeId?.label || ''}
                        table={['typeitems', 'type']}
                        listType={{ id: 'itemId', name: 'itemName' }}
                        joins={'typeitems.typeId = type.typeId'}
                        conditions={[`typeitems.archive = 'N'`, `type.subMenuId = ${submenu}`]}
                        onChange={(_e, newVal, reason) => {
                          if (reason === 'clear') {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              simpleTaskTypeId: '',
                            }));
                          } else {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              simpleTaskTypeId: newVal.value,
                            }));
                          }
                          saveView('simpleTaskReportsFilter', { simpleTaskTypeId: newVal });
                        }}
                      />
                    </div>

                    {params.SIMPLETASK_RESOURCE_FIELD === 'Y' && (
                      <div className="col-span-2 md:col-span-1">
                        <AutoCompleteSelectUI
                          id="MachineType"
                          label={<span className="text-labelColor">Erőforrás</span>}
                          table="machines"
                          selectedValue={filterFormState.machineId}
                          value={savedFilters?.machineId?.label || ''}
                          listType={{ id: 'machineId', name: 'machineName' }}
                          onChange={(_e, newVal, reason) => {
                            if (reason === 'clear') {
                              setFilterFormState((prevState) => ({
                                ...prevState,
                                machineId: '',
                              }));
                            } else {
                              setFilterFormState((prevState) => ({
                                ...prevState,
                                machineId: newVal.value,
                              }));
                            }
                            saveView('simpleTaskReportsFilter', { machineId: newVal });
                          }}
                        />
                      </div>
                    )}

                    <div className="col-span-2 md:col-span-1">
                      <AutoCompleteSelectUI
                        id="status"
                        label={<span className="text-labelColor">Státusz</span>}
                        selectedValue={filterFormState.statusId}
                        value={savedFilters?.statusId?.label || ''}
                        table={['statusitems', 'status']}
                        listType={{ id: 'itemId', name: 'itemName' }}
                        joins={'statusitems.statusId = status.statusId'}
                        conditions={[`statusitems.archive = 'N'`, `status.subMenuId = ${submenu}`]}
                        onChange={(_e, newVal, reason) => {
                          if (reason === 'clear') {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              statusId: '',
                            }));
                          } else {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              statusId: newVal.value,
                            }));
                          }
                          saveView('simpleTaskReportsFilter', { statusId: newVal });
                        }}
                      />
                    </div>

                    {params.SIMPLETASK_COMPANY_LOCATION_FIELD === 'Y' && (
                      <div className="col-span-2 md:col-span-1">
                        <AutoCompleteSelectUI
                          id="companyLocation"
                          label={<span className="text-labelColor">Telephely</span>}
                          selectedValue={filterFormState.companyLocationId}
                          value={savedFilters?.companyLocationId?.label || ''}
                          table={['companyLocation']}
                          listType={{ id: 'id', name: 'name' }}
                          conditions={[`companyLocation.archive = 'N'`]}
                          onChange={(_e, newVal, reason) => {
                            if (reason === 'clear') {
                              setFilterFormState((prevState) => ({
                                ...prevState,
                                companyLocationId: '',
                              }));
                            } else {
                              setFilterFormState((prevState) => ({
                                ...prevState,
                                companyLocationId: newVal.value,
                              }));
                            }
                            saveView('simpleTaskReportsFilter', { companyLocationId: newVal });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="inline-block min-w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
                <div className="z-40 min-w-full h-[100%]">
                  <DataGridUI
                    initialState={query}
                    rows={reports.rows}
                    rowCount={reports.rowCount}
                    columns={columns}
                    onRowClick={(rowData) => {
                      if (!viewField) return;
                      setSelectedValues(rowData.row);
                    }}
                    paginationMode="server"
                    onPaginationModelChange={onPaginitonChange}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    disableRowSelectionOnClick={!viewField}
                    name="simpleTaskReports"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimpleTaskReports;
