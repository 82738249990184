import React, { useState, useEffect, useRef } from 'react';
import { DataGrid, huHU, useGridApiRef } from '@mui/x-data-grid';
import _ from 'lodash';
import useSaveView from '../../hooks/useSaveView';
import useView from '../../hooks/useView';

const DataGridUI = (props) => {
  const [prevState, setPrevState] = useState(null);
  const apiRef = useGridApiRef();
  const saveView = useSaveView();
  const dataGrid = useView('dataGrid');
  const restoredRef = useRef(false);
  const [loading, setLoading] = useState(true);

  const debouncedHandleStateChange = _.debounce((currentState) => {
    if (props.name && prevState && !_.isEqual(prevState, currentState)) {
      saveView('dataGrid', { [props.name]: currentState });
    }
    setPrevState(currentState);
  }, 500);

  useEffect(() => {
    const handleStateChange = () => {
      if (!loading) {
        const currentState = apiRef.current.exportState();
        debouncedHandleStateChange(currentState);
      }
    };

    const unsubscribe = apiRef.current.subscribeEvent('stateChange', handleStateChange);
    return () => {
      unsubscribe();
      debouncedHandleStateChange.cancel();
    };
  }, [prevState, debouncedHandleStateChange]);

  useEffect(() => {
    if (!restoredRef.current && dataGrid?.[props.name]) {
      apiRef.current.restoreState(dataGrid[props.name]);
      restoredRef.current = true;
    }
    setLoading(false);
  }, [apiRef, dataGrid, props.name]);

  const dataGridStyles = {
    root: {
      height: 650,
      width: '100%',
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#B3C8D3',
      },
      '& .MuiDataGrid-row.Mui-selected:hover': {
        backgroundColor: '#B3C8D3',
      },
      '& .MuiDataGrid-row:nth-of-type(even)': {
        backgroundColor: '#EBEAEA',
      },
      '& .MuiDataGrid-row:nth-of-type(even):hover': {
        backgroundColor: '#EDF4FB',
      },
      '& .MuiDataGrid-row:nth-of-type(odd):hover': {
        backgroundColor: '#EDF4FB',
      },
      '& .MuiDataGrid-row.Mui-selected:nth-of-type(even)': {
        backgroundColor: '#B3C8D3',
      },
      '& .MuiDataGrid-row:nth-of-type(odd)': {
        backgroundColor: '#F7F7F7',
      },
      '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd)': {
        backgroundColor: '#B3C8D3',
      },
      '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd):hover': {
        backgroundColor: '#A7B9C2',
      },
      '& .MuiDataGrid-row.Mui-selected:nth-of-type(even):hover': {
        backgroundColor: '#A7B9C2',
      },
    },
  };

  return (
    <DataGrid
      rowHeight={45}
      sx={dataGridStyles.root}
      slotProps={{
        columnsPanel: {
          sx: {
            '& .MuiDataGrid-panelFooter button:first-child': {
              display: 'none',
            },
          },
        },
      }}
      localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
      pageSize={5}
      apiRef={apiRef}
      disableSelectionOnClick
      loading={loading}
      {...props}
    />
  );
};

export default DataGridUI;
