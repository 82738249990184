import { useEffect, useState } from 'react';
import useMenus from '../context/MenuContext';
import useParam from '../context/ParamContext';

export const useSimpleTask = () => {
  const { menus, getMenus, getMenuSubMenuId } = useMenus();
  const { getParam, params } = useParam();
  const [refetchTable, setRefetchTable] = useState(true);
  const [viewField, setViewField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [submenu, setSubmenu] = useState(null);

  useEffect(() => {
    getMenus();
    getParam();
  }, []);

  useEffect(() => {
    setSubmenu(getMenuSubMenuId('simpleTask'));
  }, [menus]);

  return {
    refetchTable,
    setRefetchTable,
    viewField,
    setViewField,
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    params,
  };
};
