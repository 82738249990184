import { useState, useEffect } from 'react';

import { InputUI, DataGridUI } from '../Interface';
import { dataGridColumns, generateFilter } from '../../utils/helper';
import companyLocationService from '../../service/companyLocation.service';

export default function CompanyLocationTable({ refetch, values, viewField, operatorsDisabled }) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    name: '',
  });

  const conditions = {
    like: ['name'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    companyLocationService.getCompanyLocationReport(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'name',
      headerName: 'Telephely',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
    },
  ]);

  return (
    <>
      <div className="col-span-4 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="col h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto xxl:overflow-x-hidden">
              <div className="sticky top-0 bg-white">
                <div className="pt-2 ">
                  <div>
                    <div className="grid grid-cols-4 gap-x-4 mx-4">
                      <div className="col-span-2 md:col-span-1">
                        <InputUI
                          label="Telephely neve"
                          InputLabelProps={{
                            style: { color: '#607d8b' },
                          }}
                          variant="standard"
                          onChange={(e) => {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              name: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inline-block min-w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
                <div className="z-40 min-w-full h-[100%]">
                  <DataGridUI
                    initialState={query}
                    rows={reports.rows}
                    rowCount={reports.rowCount}
                    columns={columns}
                    onRowClick={(rowData) => {
                      if (!viewField) return;
                      setSelectedValues(rowData.row);
                    }}
                    paginationMode="server"
                    onPaginationModelChange={onPaginitonChange}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    disableRowSelectionOnClick={!viewField}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
