import React, { useState, useEffect } from 'react';
import { InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import InputUI from './InputUI';
import { isNil } from 'lodash';

const DebouncedInputUI = (props) => {
  const { setQuickFilterSearchValue, quickFilterSearchValue, debounceMs } = props;
  const [searchValue, setSearchValue] = useState(quickFilterSearchValue || '');

  useEffect(() => {
    if (quickFilterSearchValue !== searchValue) {
      setSearchValue(quickFilterSearchValue);
    }
  }, [quickFilterSearchValue]);

  useEffect(() => {
    if (searchValue === quickFilterSearchValue) return;
    const delayDebounceFn = setTimeout(() => {
      if (!isNil(searchValue)) setQuickFilterSearchValue(searchValue);
    }, debounceMs);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, quickFilterSearchValue]);

  return (
    <InputUI
      value={searchValue}
      variant="standard"
      onChange={(event) => {
        setSearchValue(event.target.value);
      }}
      InputLabelProps={{
        style: { color: '#607d8b' },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" onClick={() => setSearchValue('')}>
            {searchValue ? <ClearIcon color="primary" style={{ cursor: 'pointer' }} /> : <div style={{ width: 24 }} />}
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default DebouncedInputUI;
